import { Component, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { LucideAngularModule } from 'lucide-angular';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { SidebarModule } from 'primeng/sidebar';
import { StyleClassModule } from 'primeng/styleclass';
import { TooltipModule } from 'primeng/tooltip';
import { AuthService } from 'shared/services/auth.service';

@Component({
    selector: 'app-sidenav',
    standalone: true,
    templateUrl: './sidenav.component.html',
    styleUrl: './sidenav.component.scss',
    imports: [
        LucideAngularModule,
        SidebarModule,
        ButtonModule,
        RouterLink,
        RouterLinkActive,
        TooltipModule,
        RippleModule,
        StyleClassModule,
    ],
})
export class SidenavComponent {
    constructor(private router: Router) {}

    private authServive = inject(AuthService);
    sidebarVisible: boolean = true;
    colapseMenu: boolean = false;

    user = toSignal(this.authServive.getUserData());

    currentRouter(): boolean {
        return this.router.url.includes('consistencias');
    }
}
