import { provideHttpClient } from '@angular/common/http';
import { ApplicationConfig } from '@angular/core';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {
    PreloadAllModules,
    provideRouter,
    withPreloading,
} from '@angular/router';
import { provideEchartsModule } from 'shared/libs/echarts/echarts.provider';
import { provideLucideModule } from 'shared/libs/lucide/lucide.provider';
import { routes } from './app.routes';

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(routes, withPreloading(PreloadAllModules)),
        provideHttpClient(),
        provideAnimationsAsync(),
        provideEchartsModule(),
        provideLucideModule(),
    ],
};
