import { Routes } from '@angular/router';
import { MainTemplateComponent } from './shared/components/main-template/main-template.component';
import NotFoundComponent from './shared/components/not-found/not-found.component';
import { authGuard } from './shared/guards/auth.guard';

export const routes: Routes = [
    {
        path: 'login',
        loadComponent: () =>
            import('./shared/components/login/login.component'),
    },
    {
        path: '',
        component: MainTemplateComponent,
        canActivate: [authGuard],
        children: [
            {
                path: '',
                redirectTo: '/monitoramento',
                pathMatch: 'full',
            },
            {
                path: 'monitoramento',
                loadChildren: () =>
                    import('./features/monitoring/monitoring.routes'),
            },
            {
                path: 'assistente-virtual',
                loadComponent: () =>
                    import('./features/assistant/assistant.component'),
            },
            {
                path: 'consistencias',
                loadChildren: () =>
                    import('./features/consistency/consistency.routes'),
            },
        ],
    },
    {
        path: '**',
        component: NotFoundComponent,
    },
];
