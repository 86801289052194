import { Component } from '@angular/core';
import { LucideAngularModule } from 'lucide-angular';
import { SidenavComponent } from '../sidenav/sidenav.component';

@Component({
    selector: 'app-not-found',
    standalone: true,

    template: `
        <div class="flex w-full h-screen">
            <app-sidenav />
            <div
                class="flex w-full flex-column align-items-center justify-content-center">
                <div class="circle">
                    <i-lucide
                        class="font-size-zero"
                        name="octagon-x"
                        [size]="80"
                        color="#4b5563" />
                </div>
                <p>Página não encontrada</p>
            </div>
        </div>
    `,
    styles: `
        .circle {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 120px;
            height: 120px;
            border-radius: 50%;
            background-color: #ffffff;
        }

        p {
            color: #4b5563;
            font-size: 18px;
            font-weight: 500;
            margin-top: 32px;
        }
    `,
    imports: [SidenavComponent, LucideAngularModule],
})
export default class NotFoundComponent {}
