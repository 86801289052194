import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import lang from 'shared/libs/primeng/pt-br';

@Component({
    selector: 'app-root',
    standalone: true,
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
    imports: [RouterOutlet],
})
export class AppComponent implements OnInit {
    constructor(private primengConfig: PrimeNGConfig) {}

    sidebarVisible: boolean = false;

    ngOnInit() {
        this.primengConfig.ripple = true;
        this.primengConfig.setTranslation(lang['pt-br']);
    }
}
