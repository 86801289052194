import {
    EnvironmentProviders,
    importProvidersFrom,
    makeEnvironmentProviders,
} from '@angular/core';
import {
    AreaChart,
    ChevronDown,
    ChevronUp,
    ChevronsLeft,
    ChevronsRight,
    CircleStop,
    Download,
    Expand,
    FileBarChart,
    GitBranch,
    LayoutDashboard,
    LoaderCircle,
    LucideAngularModule,
    MapPin,
    MessageCircleWarning,
    MessageSquareDiff,
    MessageSquarePlus,
    MessageSquareText,
    MessagesSquare,
    Mic,
    OctagonX,
    Percent,
    RefreshCw,
    RotateCcw,
    ScrollText,
    Search,
    SendHorizontal,
    Settings2,
    Sparkle,
    Sparkles,
    SwitchCamera,
    TriangleAlert,
    Upload,
    Waypoints,
    X,
} from 'lucide-angular';

export function provideLucideModule(): EnvironmentProviders {
    return makeEnvironmentProviders([
        importProvidersFrom(
            LucideAngularModule.pick({
                LayoutDashboard,
                MapPin,
                Download,
                ChevronsLeft,
                AreaChart,
                Sparkles,
                Mic,
                CircleStop,
                X,
                Search,
                MessageSquarePlus,
                SendHorizontal,
                MessagesSquare,
                LoaderCircle,
                GitBranch,
                Waypoints,
                MessageSquareDiff,
                SwitchCamera,
                MessageCircleWarning,
                Sparkle,
                MessageSquareText,
                ChevronsRight,
                Expand,
                Upload,
                Settings2,
                TriangleAlert,
                ScrollText,
                FileBarChart,
                Percent,
                ChevronDown,
                ChevronUp,
                RotateCcw,
                RefreshCw,
                OctagonX,
            })
        ),
    ]);
}
