import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AuthDto } from 'shared/interfaces/auth-dto';
import { user } from 'shared/mocks/user/user';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    httpClient = inject(HttpClient);

    getUserData(): Observable<AuthDto> {
        return of(user);

        return this.httpClient.get<AuthDto>(`${environment.apiUrl}/auth/user`);
    }

    isLoggedIn(): boolean {
        return true;
    }
}
