import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { SidenavComponent } from '../sidenav/sidenav.component';

@Component({
    selector: 'app-main-template',
    standalone: true,
    templateUrl: './main-template.component.html',
    styleUrl: './main-template.component.scss',
    imports: [RouterOutlet, SidenavComponent],
})
export class MainTemplateComponent {}
