<nav [class.docked]="!sidebarVisible">
    <div class="section-items text-100">
        <!-- header section -->
        <div class="header">
            <span class="inline-flex align-items-center gap-2">
                <img src="assets/icon.svg" alt="icon verter" />
                <span class="font-semibold text-2xl app-name">Vérter</span>
            </span>
            <span>
                <p-button
                    type="button"
                    [outlined]="true"
                    severity="secondary"
                    styleClass="h-3rem w-3rem p-2"
                    (click)="
                        sidebarVisible = !sidebarVisible; colapseMenu = false
                    ">
                    <i-lucide
                        class="font-size-zero"
                        [name]="
                            sidebarVisible ? 'chevrons-left' : 'chevrons-right'
                        " />
                </p-button>
            </span>
        </div>
        <!-- items menu section -->
        <div class="sidenav-options">
            <ul class="list-none p-0 m-0">
                <li>
                    <ul
                        class="flex flex-column gap-3 list-none p-0 m-0 overflow-hidden">
                        <p class="section-text m-0"><span>Visão Geral</span></p>
                        <li
                            pTooltip="Dashboard"
                            tooltipPosition="right"
                            [tooltipDisabled]="sidebarVisible">
                            <a
                                class="flex align-items-center cursor-pointer border-round hover:surface-800 p-ripple disable">
                                <div class="bg-icon">
                                    <i-lucide
                                        class="font-size-zero"
                                        name="layout-dashboard" />
                                </div>
                                <p class="item-list">Dashboard</p>
                            </a>
                        </li>
                        <li
                            pTooltip="Monitoramento"
                            tooltipPosition="right"
                            [tooltipDisabled]="sidebarVisible">
                            <!-- routerLink="/monitoramento"
                            routerLinkActive="active" -->
                            <a
                                class="flex align-items-center cursor-pointer border-round hover:surface-800 p-ripple disable">
                                <div class="bg-icon">
                                    <i-lucide
                                        class="font-size-zero"
                                        name="area-chart" />
                                </div>
                                <p class="item-list">Monitoramento</p>
                            </a>
                        </li>
                        <p class="section-text m-0"><span>Cenários</span></p>
                        <li
                            pTooltip="Assistente Virtual"
                            tooltipPosition="right"
                            [tooltipDisabled]="sidebarVisible">
                            <a
                                routerLink="/assistente-virtual"
                                routerLinkActive="active"
                                pRipple
                                class="flex align-items-center cursor-pointer border-round hover:surface-800 p-ripple">
                                <div class="bg-icon">
                                    <i-lucide
                                        class="font-size-zero"
                                        name="sparkles" />
                                </div>
                                <p class="item-list">Assistente Virtual</p>
                            </a>
                        </li>
                        <li
                            pTooltip="Simulação"
                            tooltipPosition="right"
                            [tooltipDisabled]="sidebarVisible">
                            <a
                                class="flex align-items-center cursor-pointer border-round hover:surface-800 p-ripple disable">
                                <div class="bg-icon">
                                    <i-lucide
                                        class="font-size-zero"
                                        name="git-branch" />
                                </div>
                                <p class="item-list">Simulação</p>
                            </a>
                        </li>
                        <li
                            pTooltip="Previsão"
                            tooltipPosition="right"
                            [tooltipDisabled]="sidebarVisible">
                            <a
                                class="flex align-items-center cursor-pointer border-round hover:surface-800 p-ripple disable">
                                <div class="bg-icon">
                                    <i-lucide
                                        class="font-size-zero"
                                        name="waypoints" />
                                </div>
                                <p class="item-list">Previsão</p>
                            </a>
                        </li>
                        <p class="section-text m-0">
                            <span>Configuração</span>
                        </p>
                        <li
                            pTooltip="Comunicação"
                            tooltipPosition="right"
                            [tooltipDisabled]="sidebarVisible">
                            <a
                                pRipple
                                class="flex align-items-center cursor-pointer border-round hover:surface-800 p-ripple disable">
                                <div class="bg-icon">
                                    <i-lucide
                                        class="font-size-zero"
                                        name="message-square-diff" />
                                </div>
                                <p class="item-list">Comunicação</p>
                            </a>
                        </li>
                        @if (sidebarVisible) {
                            <li
                                pTooltip="Consistências"
                                tooltipPosition="right"
                                [tooltipDisabled]="sidebarVisible">
                                <a
                                    (click)="colapseMenu = !colapseMenu"
                                    [class.active]="currentRouter()"
                                    pRipple
                                    pStyleClass="@next"
                                    enterClass="hidden"
                                    enterActiveClass="slidedown"
                                    leaveToClass="hidden"
                                    leaveActiveClass="slideup"
                                    class="flex align-items-center cursor-pointer border-round hover:surface-800 p-ripple">
                                    <div class="bg-icon">
                                        <i-lucide
                                            class="font-size-zero"
                                            name="switch-camera" />
                                    </div>
                                    <p class="item-list">Consistências</p>
                                    <i-lucide
                                        class="font-size-zero ml-auto mr-1"
                                        [color]="
                                            colapseMenu ? '#ffffff' : '#99a0ab'
                                        "
                                        [name]="
                                            colapseMenu
                                                ? 'chevron-up'
                                                : 'chevron-down'
                                        " />
                                </a>
                                <!-- ul color #0a0f1f -->
                                <ul
                                    class="list-none mt-4 py-0 px-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                                    <li>
                                        <span class="sub-item-title"
                                            >Estações</span
                                        >
                                        <a
                                            pRipple
                                            routerLink="/consistencias/estacoes/nivel"
                                            routerLinkActive="active-sub-item"
                                            class="flex align-items-center cursor-pointer p-3 mt-4 border hover:surface-800 transition-duration-150 p-ripple">
                                            <span class="item-list">Nível</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            pRipple
                                            routerLink="/consistencias/estacoes/chuva"
                                            routerLinkActive="active-sub-item"
                                            class="flex align-items-center cursor-pointer p-3 border hover:surface-800 transition-duration-150 p-ripple">
                                            <span class="item-list">Chuva</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            pRipple
                                            routerLink="/consistencias/estacoes/vazao"
                                            routerLinkActive="active-sub-item"
                                            class="flex align-items-center cursor-pointer p-3 mb-4 border hover:surface-800 transition-duration-150 p-ripple">
                                            <span class="item-list">Vazão</span>
                                        </a>
                                    </li>
                                    <li>
                                        <span class="sub-item-title"
                                            >Parâmetros</span
                                        >
                                        <a
                                            pRipple
                                            routerLink="/consistencias/parametros/nivel"
                                            routerLinkActive="active-sub-item"
                                            class="flex align-items-center cursor-pointer p-3 mt-4 border hover:surface-800 transition-duration-150 p-ripple">
                                            <span class="item-list"
                                                >Parâmetros de nível</span
                                            >
                                        </a>
                                        <a
                                            pRipple
                                            routerLink="/consistencias/parametros/chuva"
                                            routerLinkActive="active-sub-item"
                                            class="flex align-items-center cursor-pointer p-3 border hover:surface-800 transition-duration-150 p-ripple">
                                            <span class="item-list"
                                                >Parâmetros de chuva</span
                                            >
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        } @else {
                            <li
                                pTooltip="Consistências"
                                tooltipPosition="right"
                                [tooltipDisabled]="sidebarVisible">
                                <a
                                    [class.active]="currentRouter()"
                                    class="flex align-items-center cursor-pointer border-round hover:surface-800 p-ripple">
                                    <div class="bg-icon">
                                        <i-lucide
                                            class="font-size-zero"
                                            name="switch-camera" />
                                    </div>
                                    <p class="item-list">Consistências</p>
                                </a>
                            </li>
                        }

                        <li
                            pTooltip="Alertas"
                            tooltipPosition="right"
                            [tooltipDisabled]="sidebarVisible">
                            <a
                                class="flex align-items-center cursor-pointer border-round hover:surface-800 p-ripple disable">
                                <div class="bg-icon">
                                    <i-lucide
                                        class="font-size-zero"
                                        name="message-circle-warning" />
                                </div>
                                <p class="item-list">Alertas</p>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
        <!-- user section -->
        <div class="section-user">
            <div class="user-image">
                <img [src]="user()?.avatarURL" alt="user image" />
            </div>
            <div class="user-info">
                <span class="user-name">{{ user()?.username }}</span>
                <span class="user-role">{{ user()?.role }}</span>
            </div>
        </div>
        <!-- footer section -->
        <div class="section-footer">CEMIG <span>© 2024</span></div>
    </div>
</nav>
