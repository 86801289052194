import {
    EnvironmentProviders,
    importProvidersFrom,
    makeEnvironmentProviders,
} from '@angular/core';
import { NgxEchartsModule } from 'ngx-echarts';

export function provideEchartsModule(): EnvironmentProviders {
    return makeEnvironmentProviders([
        importProvidersFrom(
            NgxEchartsModule.forRoot({
                echarts: () => import('./echarts').then(m => m.default),
            })
        ),
    ]);
}
